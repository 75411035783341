label {
  &.error {
    color: $error;
    font-size: 12px;
  }
}

.firstChildHeaderRowANTD {
  width: 260px;
}

.firstChildHeaderRowANTD label {
  width: 80px;
}

.firstChildHeaderRowANTD .ant-select.ant-select-in-form-item {
  width: 97%;
}

.secondChildHeaderRowANTD {
  width: 350px;
}

.secondChildHeaderRowANTD label {
  width: 100px;
}

.secondChildHeaderRowANTD .ant-select.ant-select-in-form-item {
  width: 97%;
}

.thirdChildHeaderRowANTD {
  margin-left: auto;
}

.firstChildInRowFirstSectionANTD {
  width: 470px;
}

.firstChildInRowFirstSectionANTD label {
  width: 210px;
}

.firstChildInRowFirstSectionANTD .ant-select.ant-select-in-form-item {
  width: 97%;
}

.firstChildInRowFirstSectionANTD input {
  width: 97%;
}

.firstChildInRowFirstSectionANTD .ant-input-number-in-form-item {
  width: 97%;
}

.firstChildInRowFirstSectionANTD textarea {
  width: 97%;
}

.firstChildInRowSecondSectionANTD {
  width: 580px;
}

.firstChildInRowSecondSectionANTD label {
  width: 310px;
}

.firstChildInRowSecondSectionANTD input {
  width: 97%;
}

.firstChildInRowSecondSectionANTD .ant-input-number-in-form-item {
  width: 97%;
}

.firstChildInRowSecondSectionANTD .ant-select.ant-select-in-form-item {
  width: 97%;
}

.log {
  .card-wrapper {
    text-align: center;
    position: absolute;
    top: 40%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    min-width: 500px;
    @include media("<md") {
      min-width: 320px;
    }

    .ant-card-head-title {
      font-size: 18px;
      font-weight: 700;
      // color: #ffffff;
      color: #2c2638;
      font-weight: 100;
    }

    .login-logo {
      margin-bottom: 30px;
      width: 300px;
    }
  }
}

.dashboard {
  height: 100%;

  .card-wrapper {
    display: flex;
    height: 100%;

    .ant-card {
      width: 50%;

      @include media("<lg") {
        width: 100%;
      }

      .ant-card-body {
        padding: 10px 15px;
      }
    }
  }
}

.ant-form-item-explain div {
  float: left;
}

.ant-form-item.upload-wrapper.right {
  .ant-form-item-control {
    flex-direction: row;
    justify-content: flex-start;
  }
  .ant-upload-list-picture-card-container,
  .ant-upload.ant-upload-select-picture-card {
    margin: 0;
  }
}

.ant-card {
  &.login-header {
    .ant-card-head {
      color: #2c2638;
      background-color: #ffffff;
    }
  }
}

.ant-card-head {
  // background-color: #4f66a0;
  // color: #ffffff;
  // color: #2c2638;
  color: #ffffff;
  // background-color: #ffffff;
  background-color: #216c97;
  text-transform: capitalize;

  .ant-card-head-title {
    //added
    text-transform: initial;
  }
}

.ant-picker-large {
  padding: 5px 11px 6px;
}

.panel-primary > .panel-heading {
  color: #ffffff;
  background-color: #4f66a0;
  border-color: #4f66a0;
}

.panel-heading .panel-title span {
  background-color: #4f66a0 !important;
}

.log {
  .card-wrapper {
    .ant-card-head-title {
      text-align: center;
    }
  }
}

.ant-card {
  &.profile-title {
    .ant-card-head {
      background-color: #848587;
      color: #ffffff;
    }
  }
}

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  //background: #4f66a0;
  //color: #216c97;
  color: #246997;
  background-color: rgb(255, 255, 255);
}



.ant-menu-dark .ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-active,
.ant-menu-dark .ant-menu-submenu-active,
.ant-menu-dark .ant-menu-submenu-open,
.ant-menu-dark .ant-menu-submenu-selected,
.ant-menu-dark .ant-menu-submenu-title:hover {
  color: darken(#246997, 15);
}

.ant-menu-dark .ant-menu-item > span > a {
  color: #246997;
}

.ant-menu-dark .ant-menu-item > span > a:hover {
  color: darken(#246997, 15);
}

.ant-menu-dark .ant-menu-item-selected > span > a {
  color: #fff !important;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #246997 !important;
}

@media only screen and (max-width: 400px) {
  .ant-card-head {
    padding: 12px;
    font-size: 14px;
  }

  .ant-card-body {
    padding: 12px;
    font-size: 12px;
  }
}

.ant-form-item-label {
  min-width: 100px;
}
#FormItem .ant-form-item-control {
  overflow: visible !important;
}

input,
select {
  font-size: 100% !important;
}

.link_menuitem{
  color: #246997;
  // background-color: rgb(255, 255, 255);
}

.ant-menu-item-selected > .ant-menu-title-content > .link_menuitem{
  color: #ffffff !important;
  background-color: #246997 !important;
}

.ant-menu-item-selected {
  background-color: #246997 !important;
}
