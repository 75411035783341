main.ant-layout-content {
  section.ant-layout.ant-layout-has-sider.site-layout-background {
    height: 100%;
  }
}

.ant-layout-sider {
  .logo {
    text-align: center;
    padding: 15px;

    img {
      max-height: 100%;
      max-width: 100%;
    }

    Button {
      margin-top: 15px;
    }
  }

  .ant-menu-submenu-title {
    span {
      i {
        vertical-align: inherit;
        position: relative;
        top: -1px;
      }
    }

    .ant-menu-submenu-arrow {
      transform: translateY(2px);
    }
  }

  .ant-menu-submenu-open {
    .ant-menu-submenu-title {
      .ant-menu-submenu-arrow {
        transform: translateY(0);
      }
    }
  }

  .ant-menu-submenu-title {
    @include media("<=md") {
    }
  }

  .ant-menu-item {
    margin-top: 0;
    margin-bottom: 0;

    &:first-child {
      margin-top: 8px;
    }

    &:last-child {
      margin-bottom: 8px;
    }

    a,
    a:visited {
      &:hover {
        text-decoration: none;
      }
    }
  }
}

.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 0;
  margin-top: 0;
}
.ant-layout-sider .ant-menu-item:last-child {
  margin-bottom: 0;
}

span.anticon {
  vertical-align: 0.15em;
}

// main.ant-layout-content {
//   padding: 0 0 0 15px !important;
// }

@media only screen and (max-width: 992px) {
  .ant-layout-sider {
    .logo {
      border: none;
      padding: 20px 0;
      padding-left: 3px;
      padding-right: 3px;
    }
  }

  main.ant-layout-content
    section.ant-layout.ant-layout-has-sider.site-layout-background {
    display: flex;
    flex-direction: column;
  }

  .ant-layout.ant-layout-has-sider > .ant-layout-content {
    width: 100%;
    padding: 0px !important;
  }
}
