html {
  min-height: 100%;
  height: 100%;
}

body {
  background: $bodyColor;
  min-width: 320px;
  height: 100%;
}

#__next {
  height: 100%;
}

.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-dark
  .ant-menu-sub
  .ant-menu-submenu-title
  .ant-menu-submenu-arrow::after,
.ant-menu.ant-menu-dark
  .ant-menu-sub
  .ant-menu-submenu-title
  .ant-menu-submenu-arrow::after,
.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-dark
  .ant-menu-sub
  .ant-menu-submenu-title
  .ant-menu-submenu-arrow::before,
.ant-menu.ant-menu-dark
  .ant-menu-sub
  .ant-menu-submenu-title
  .ant-menu-submenu-arrow::before {
  background: #246997;
}

.formitemANTDTwo {
  min-width: 100%;
  max-width: 1000px;
}

.subtitle {
  display: flex;
  color: #216c97;
  background-color: #d9d9d9;
  height: 20px;
  margin-bottom: 20px;
  padding: 16px;
  width: 35%;
  justify-content: center;
  align-items: center;
}

.ant-layout-sider-dark {
  background: #ffffff;
  position: fixed;
  z-index: 2;
  height: 100vh;

  @include media(">=lg") {
    flex: 0 0 213px !important;
    max-width: 213px !important;
    min-width: 213px !important;
    width: 213px !important;
  }
}

#admin-layout {
  height: 100%;
  overflow: hidden;

  section {
    padding: 20px 0;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }

    &.ant-layout {
      padding: 0;
      overflow: hidden;
    }
  }

  .ant-layout-sider {
    background: #2c2738;
    min-height: 100vh;
    z-index: 150;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .ant-layout-content {
    padding: 15px 15px 0;
    overflow-x: hidden;
    overflow-y: auto;

    > *:last-child {
      margin-bottom: 15px;
    }
  }
}

.admin_header {
  @include media("<=lg") {
    padding-left: 20px !important;
  }
}

header.ant-layout-header {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 61px;

  @include media("<=lg") {
    flex-direction: row;
    justify-content: center;
    z-index: 2 !important;
    align-items: center;

    > button {
      margin-right: auto;
    }

    .ant-dropdown-link {
      margin-left: auto;
    }
  }

  span {
    margin-top: 10px;
  }
}

header.ant-layout-header {
  // background: #2c2738;
  background: #ffffff;
}

.ant-menu-title-content span {
  color: #2574ab;
}

.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark,
.ant-menu.ant-menu-dark .ant-menu-sub {
  color: #2574ab;
}

.ant-menu-dark .ant-menu-item > span > a {
  color: #246997;
}

.ant-menu-item .ant-menu-item-active span {
  background: yellow;
}

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  // background: #4f66a0;
  background: #ffffff;
}

.ant-menu.ant-menu-dark {
  @include media(">=lg") {
    margin-top: -3px;
  }
}

.content-space {
  margin-top: 64px;
  margin-left: 210px;
}

@media only screen and (max-width: 992px) {
  .content-space {
    margin-left: 0px;
  }
}

.ant-form-item-control {
  overflow: visible;
}
