th,
td {
  font-size: 0.875em;
}

th {
  text-align: left;
}

.table {
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border-radius: 2px;

  > thead > tr > th,
  > tbody > tr > th,
  > tfoot > tr > th,
  > thead > tr > td,
  > tbody > tr > td,
  > tfoot > tr > td {
    padding: 13px 15px;
    line-height: 1.42857143;
    vertical-align: top;
    border-top: 1px solid #ffffff;
  }

  > thead > tr > th {
    vertical-align: bottom;
    border-bottom: 2px solid #ffffff;
    text-transform: uppercase;
  }

  > caption + thead > tr:first-child > th,
  > colgroup + thead > tr:first-child > th,
  > thead:first-child > tr:first-child > th,
  > caption + thead > tr:first-child > td,
  > colgroup + thead > tr:first-child > td,
  > thead:first-child > tr:first-child > td {
    border-top: 0;
  }

  > tbody {
    + tbody {
      border-top: 2px solid #ffffff;
    }

    tr {
      &:hover {
        > td {
          background-color: #f0f6f8;
        }
      }
    }
  }
}

.table > thead > tr:first-child {
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
}
.table > thead > tr:first-child > th:first-child {
  border-top-left-radius: 2px;
}
.table > thead > tr:first-child > th:last-child {
  border-top-right-radius: 2px;
}
.table > tfoot > tr:last-child {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}
.table > tfoot > tr:last-child > th:first-child {
  border-bottom-left-radius: 2px;
}
.table > tfoot > tr:last-child > th:last-child {
  border-bottom-right-radius: 2px;
}
.table > tbody > tr:last-child > th:first-child,
.table > tbody > tr:last-child > td:first-child {
  border-bottom-left-radius: 2px;
}
.table > tbody > tr:last-child > th:last-child,
.table > tbody > tr:last-child > td:last-child {
  border-bottom-right-radius: 2px;
}
.table > tfoot + tbody > tr:last-child > th:first-child,
.table > tfoot + tbody > tr:last-child > td:first-child {
  border-bottom-left-radius: 0;
}
.table > tfoot + tbody > tr:last-child > th:last-child,
.table > tfoot + tbody > tr:last-child > td:last-child {
  border-bottom-right-radius: 0;
}

.table-condensed > thead > tr > th,
.table-condensed > tbody > tr > th,
.table-condensed > tfoot > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > tbody > tr > td,
.table-condensed > tfoot > tr > td {
  padding: 5px;
}

.table-bordered {
  border: 1px solid #ffffff;

  > thead > tr > th,
  > tbody > tr > th,
  > tfoot > tr > th,
  > thead > tr > td,
  > tbody > tr > td,
  > tfoot > tr > td {
    border: 1px solid #ffffff;
  }

  > thead > tr > th,
  > thead > tr > td {
    border-bottom-width: 2px;
  }
}

.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #fcfcfd;
}
.table-hover > tbody > tr:hover {
  background-color: #e7e9ee;
}

.table-responsive {
  overflow-x: auto;
  overflow-y: hidden;
  min-height: 0.01%;

  @include media('<md') {
    width: 100%;
    margin-bottom: 12.75px;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #ffffff;
  }

  th,
  td {
    @include media('<md') {
      white-space: nowrap;
    }
  }

  > .table-bordered {
    @include media('<md') {
      border: 0;

      th {
        &:first-child {
          border-left: 0;
        }

        &:last-child {
          border-right: 0;
        }
      }
    }
  }
}

/* colors */
.table > .success > tr > th,
.table > .success > tr > td {
  background-color: #505b72;
  color: #fff;
  border-color: rgba(255, 255, 255, 0.25);
}
.table > .primary > tr > th,
.table > .primary > tr > td {
  background-color: #2574ab;
  color: #fff;
  border-color: rgba(255, 255, 255, 0.25);
}
.table > .success-2 > tr > th,
.table > .success-2 > tr > td {
  background-color: #4F66A0;
  color: #fff;
  border-color: rgba(255, 255, 255, 0.25);
}
.table > .danger > tr > th,
.table > .danger > tr > td {
  background-color: #d9534f;
  color: #fff;
  border-color: rgba(255, 255, 255, 0.25);
}
.table > .success > tr:first-child {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.table > .success > tr:first-child > th:first-child,
.table > .success > tr:first-child > td:first-child {
  border-top-left-radius: 0;
}
.table > .success > tr:first-child > th:last-child,
.table > .success > tr:first-child > td:last-child {
  border-top-right-radius: 0;
}

.table-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 15px;

  a {
    display: inline-flex;
    width: 20px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  i {
    vertical-align: baseline;
  }
}


.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  // color: #4f66a0;
  color: #000000;
}

.ant-tabs-tab:hover {
  color: #4f66a0;
}

.ant-tabs-tab-btn:focus, .ant-tabs-tab-remove:focus, .ant-tabs-tab-btn:active, .ant-tabs-tab-remove:active {
  color: #4f66a0;
}

.ant-tabs-ink-bar {
  background: #4f66a0;
}

.ant-table-row-expand-icon:focus, .ant-table-row-expand-icon:hover {
  color: #4f66a0;
}

.ant-picker:hover, .ant-picker-focused {
  border-color: #4f66a0;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner, .ant-picker-range .ant-picker-active-bar {
  background: #4f66a0;
}

.ant-table-thead th.ant-table-column-has-sorters:hover, .ant-table-thead th.ant-table-column-has-sorters:hover .ant-table-filter-trigger-container,.ant-table-filter-trigger-container-open, .ant-table-filter-trigger-container:hover, .ant-table-thead th.ant-table-column-has-sorters:hover .ant-table-filter-trigger-container:hover {
  background-color: #413d3e;
}

td.ant-table-column-sort{
  background: initial;
}

.ant-table table tbody > tr.table-row-client-uncontacted{
  background-color: #fff;
}

.ant-table table tbody > tr.table-row-client-active{
  background-color: #32CD32 !important;
}

.ant-table table tbody > tr.table-row-client-offer-sent{
  background-color: #00aeef !important;
  color:#fff !important;

  span{
    color:#000;
  }
}

.ant-table table tbody > tr.table-row-client-uninterested{
  background-color: #ff0000 !important;
  color:#fff !important;

  span{
    color:#000;
  }
}

.ant-table table tbody > tr.table-row-client-contacted{
  background-color: #ffff00 !important;
}

.ant-table-tbody tr.ant-table-row:hover td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):nth-child(2n) > td{
  background: unset;
}

tbody tr td{
  font-size: 13px !important;
  padding: 9px 6px !important;
  font-weight: 400;

  @include media("<=lg"){
    font-size: 13px !important;
  }
}

@include media(">=xl") {
   .ant-table-tbody > tr > td {
      padding: 7px 6px !important;
  }
}

.ant-table tr td{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 350px;

  @include media("<=1600px"){
    max-width: 270px;
  }

  @include media("<=1500px"){
    max-width: 200px;
  }

  @include media("<=1245px"){
    max-width: 180px;
  }

  @include media("<=lg"){
    max-width: 350px;
  }
}

.table-column-email{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 200px !important;

  @include media("<=1225px"){
    max-width: 180px !important;
  }
}

.table-column-phone{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 110px !important;
}

.table-column-idNumber{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100px !important;
}

.table-column-status{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 165px !important;

  @include media("<=1285px"){
    max-width: 146px !important;
  }
}

.table-column-address{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 175px !important;
}

.table-column-city{
  @include media("<=1430px"){
    max-width: 140px !important;
  }

  @include media("<=1270px"){
    max-width: 120px !important;
  }

  @include media("<=lg"){
    max-width: 100% !important;
  }
}

.table-column-seller{
  @include media("<=1330px"){
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 90px !important;
    width: 54px !important;
    min-width: 54px !important;
  }
}

.ant-table-thead th.ant-table-column-has-sorters{
  transition: none !important;
}

.client-table{
  @include media("<=1600px") {
    max-width: 90%;
  }

  @include media("<=1500px"){
    max-width: 80%;
  }

  @include media("<=1430px"){
    max-width: 75%;
  }

  @include media("<=1285px"){
    max-width: 70%;
  }

  @include media("<=lg"){
    max-width: 100%;
  }
}
